import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"


function SEO({ }) {
    return (
        <Helmet
          title="GRADIENT MAP"
          titleTemplate="GRADIENT MAP"
          meta={[
            {
              name: `description`,
              content: "GRADIENT MAP",
            },
            {
              property: `og:title`,
              content: "GRADIENT MAP",
            },
            {
              property: `og:description`,
              content: "GRADIENT MAP",
            },
            {
              property: `og:type`,
              content: `website`,
            },
            
          ]}
        />
      )
}    

SEO.defaultProps = {
    lang: `en`,
    meta: [],
    description: ``,
  }
  
  SEO.propTypes = {
    description: PropTypes.string,
    lang: PropTypes.string,
    meta: PropTypes.arrayOf(PropTypes.object),
    title: PropTypes.string.isRequired,
  }

export default SEO  